@import '~antd/dist/antd.css';
body {
    width: 100% !important;
    position: relative;
}

#root {
    min-height: 100vh;
    height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}